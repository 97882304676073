import { NavLink, useNavigate } from 'react-router-dom';

const NavigationBar = () => {
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('jwt');
        navigate('/login', { replace: true })
    };

    return (
        <>
            <nav className="bg-white flex flex-row justify-between">
                <div className="flex items-center">
                    <div className="ml-4 px-6 py-4">
                        <NavLink exact to="/" activeClassName="active">
                            <h1 className="text-xl font-bold">RND Kalkula</h1>
                        </NavLink>
                    </div>
                    <div className="px-6 py-4">
                        <NavLink exact to="/google-sheets" activeClassName="active">
                            <h2 className="text-sm font-bold">Google Sheets</h2>
                        </NavLink>
                    </div>
                </div>
                <div className="flex items-center mr-4">
                    <button
                        className="font-bold text-sm"
                        onClick={logout}>
                        Sign Out
                    </button>
                </div>
            </nav>
        </>
    );
}
 
export default NavigationBar;
