import { useEffect, useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';

import ConnectionService from '../services/ConnectionService';

const AddConnectionModal = ({ isOpen, googleAuthId, fetchData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [connectionName, setConnectionName] = useState(null);

    useEffect(() => {
        setIsModalOpen(isOpen);
    }, [isOpen]);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await ConnectionService.saveConnection(connectionName, googleAuthId);
        const { code, message, payload } = response;
        if (code !== 200) {
            console.log('error', message);
        } else {
            closeModal();
            fetchData();
        };
    };

    return (
        <Modal
            isOpen={isModalOpen}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                }}}
            ariaHideApp={false}>
            <div>
                <div>
                    <h1 className="text-xl font-bold">Save new connection?</h1>
                </div>
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="mt-4">
                            <label className="block text-gray-700 text-sm font-bold">
                                Connection Name
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                onChange={(e) => setConnectionName(e.target.value)} />
                        </div>
                        <div className="flex items-center justify-end mt-6">
                            <button
                                className="border bg-white hover:bg-slate-50 text-green-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                onClick={closeModal}>
                                No, Don't Save
                            </button>
                            <button
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
                                type="submit">
                                Save Connection
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
}
 
export default AddConnectionModal;
