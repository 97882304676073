import axios from 'axios';

export const service = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    headers: { 'Content-Type': 'application/json' },
})

export const protectedService = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/json'
    },
});
