import NavigationBar from "../components/NavigationBar";

const HomePage = () => {
    return (
        <>
            <NavigationBar />
            <div className="w-full h-screen bg-slate-200">

            </div>
        </>
    );
}
 
export default HomePage;