import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import AddConnectionModal from '../components/AddConnectionModal';
import NavigationBar from '../components/NavigationBar';
import ConnectionService from '../services/ConnectionService';
import GoogleService from '../services/GoogleService';

const GoogleSheetsPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [connections, setConnections] = useState([]);
    const [googleAuthId, setGoogleAuthId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        fetchData();
        initComponent();
    }, []);

    const fetchData = async () => {
        const response = await ConnectionService.getConnections();
        setConnections(response);
    };

    const initComponent = () => {
        window.history.replaceState({}, document.title);
        setIsModalOpen(state ? state.isOpen : false);
        setGoogleAuthId(state ? state.googleAuthId : null);
    };

    const signInGoogleOAuth = async () => {
        const response = await GoogleService.oauth();
        const { code, message, payload } = response;
        if (code !== 200) {
            console.log(message)
        } else {
            window.open(payload, '_blank');
        };
    };

    const openConnection = (connectionId) => {
        navigate(`/google-sheets/${connectionId}`);
    };

    return (
        <>
            <NavigationBar />
            <div className="w-full h-screen bg-slate-200">
                <div className="flex justify-center items-center py-4">
                    <button 
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={signInGoogleOAuth}>
                        Google Sign In
                    </button>
                    {googleAuthId ? <p className="ml-2">Authenticated</p> : <></>}
                </div>
                <div className="px-8">
                    <div>
                        <h2 className="text-lg font-bold">Saved Connections</h2>
                    </div>
                    {connections.map(connection => (
                        <div key={connection.id} className="bg-white shadow-md max-w-md p-2 mb-2">
                            <div className="px-4 py-2">
                                <h3 className="text-base font-semibold">{connection.name}</h3>
                            </div>
                            <div className="px-6">
                                <p>{connection.gmail_address}</p>
                                <p>{connection.updated_at}</p>
                            </div>
                            <div className="flex justify-center px-4 py-2">
                                <button
                                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    onClick={() => { openConnection(connection.id) }}>
                                    Open
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <AddConnectionModal isOpen={isModalOpen} googleAuthId={googleAuthId} fetchData={fetchData} />
        </>
    );
}
 
export default GoogleSheetsPage;
