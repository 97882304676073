import { Outlet, Navigate, } from 'react-router-dom';

const ProtectedRoutes = () => {
    const jwt = localStorage.getItem('jwt');

    return(
        !jwt ? <Navigate to='/login' /> : <Outlet />
    )
};

export default ProtectedRoutes;
