import { protectedService } from './axios';

class GoogleService {
    async oauth() {
        return protectedService.post('/google-sheets/oauth')
            .then(e => {
                const { data } = e;
                return data;
            })
            .catch(e => {
                const { response } = e;
                const { data } = response;
                return data;
            });
    };

    async oauthCallback(oauth_code) {
        return protectedService.post('/google-sheets/oauth/callback', { oauth_code })
            .then(e => {
                const { data } = e;
                return data;
            })
            .catch(e => {
                const { response } = e;
                const { data } = response;
                return data;
            });
    };
};

export default new GoogleService();
