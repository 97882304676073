import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import AuthService from '../services/AuthService';

const RegisterPage = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        const response = await AuthService.register(email, password);
        setIsLoading(false);
        const { code, message } = response;
        if (code !== 200) {
            setError(message)
        } else {
            navigate('/login', { replace: true })
        };
    };

    return (
        <>
            <div className="w-full h-screen flex flex-col justify-center items-center bg-slate-200">
                <div className="max-w-sm">
                    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8">
                        <div className="text-center">
                            <h1 className="text-xl font-bold">Register</h1>
                        </div>
                        {error && <div className="text-center"><p className="text-red-600">{error}</p></div>}
                        <div className="mt-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Email
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="mt-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Password
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="password"
                                onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className="flex items-center justify-between mt-6">
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="submit"
                                disabled={isLoading}>
                                Register
                            </button>
                            <Link
                                to="/login"
                                className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800">
                                Login
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
 
export default RegisterPage;
