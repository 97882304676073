import { Routes, Route } from 'react-router-dom';

import ProtectedRoutes from './components/ProtectedRoutes';
import HomePage from './pages/HomePage';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import GoogleSheetPage from './pages/GoogleSheetsPage';
import GoogleSheetsCallbackPage from './pages/GoogleSheetsCallbackPage';
import GoogleSheetsExplorerPage from './pages/GoogleSheetsExplorerPage';
import NotFoundPage from './pages/NotFoundPage';

function App() {
	return (
		<Routes>
			<Route path='/login' element={<LoginPage />} />
			<Route path='/register' element={<RegisterPage />} />
			<Route path='*' element={<NotFoundPage to='/404' />} />
			<Route element={<ProtectedRoutes />}>
				<Route exact path='/' element={<HomePage />} />
				<Route path='/google-sheets' element={<GoogleSheetPage />} />
				<Route path='/google-sheets/callback' element={<GoogleSheetsCallbackPage />} />
				<Route path='/google-sheets/:connectionId' element={<GoogleSheetsExplorerPage />} />
			</Route>
		</Routes>
  	);
}

export default App;
