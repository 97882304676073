import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import GoogleService from '../services/GoogleService';

const GoogleSheetsCallbackPage = () => {
    const navigate = useNavigate();

    const [oauthCode, setOauthCode] = useState(null)

    useEffect(() => {
        async function oauthCallback() {
            const response = await GoogleService.oauthCallback(oauthCode);
            const { code, message, payload } = response;
            if (code !== 200) {
                console.log('error message', message);
            } else {
                const { google_auth_id } = payload;
                navigate('/google-sheets', { state: { isOpen: true, googleAuthId: google_auth_id } });
            };
        };

        // Extract the code from the query parameters
        setOauthCode(new URLSearchParams(window.location.search).get('code'));
        oauthCallback();
    }, [oauthCode, navigate]);

    return (
        <div>Redirecting...</div>
    );
}
 
export default GoogleSheetsCallbackPage;
