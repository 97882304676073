import { service } from './axios';

class AuthService {
    async login(email, password) {
        return service.post('/auth/login', { email, password })
            .then(e => {
                const { data } = e;
                return data;
            })
            .catch(e => {
                const { response } = e;
                const { data } = response;
                return data;
            });
    };

    async register(email, password) {
        return service.post('/auth/register', { email, password })
            .then(e => {
                const { data } = e;
                return data;
            })
            .catch(e => {
                const { response } = e;
                const { data } = response;
                return data;
            });
    }
};

export default new AuthService();
