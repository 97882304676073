import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useDrivePicker from 'react-google-drive-picker'

import NavigationBar from '../components/NavigationBar';
import ConnectionService from '../services/ConnectionService';

const GoogleSheetsExplorerPage = () => {
	const { connectionId } = useParams();
    const [openPicker, authResponse] = useDrivePicker(); 

	const [spreadsheets, setSpreadsheets] = useState([]);
	const [token, setToken] = useState(null);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		const response = await ConnectionService.getConnectionGoogleSheets(connectionId);
		const { token, spreadsheets } = response
		setToken(token)
		setSpreadsheets(spreadsheets);
	};

    const handleOpenPicker = () => {
      	openPicker({
			developerKey: process.env.REACT_APP_GOOGLE_API_KEY,
			viewId: 'SPREADSHEETS',
			viewMimeTypes: 'application/vnd.google-apps.spreadsheet',
			token: token,
			supportDrives: true,
			callbackFunction: async (data) => {
				if (data.action === 'cancel') {
					console.log('User clicked cancel/close button');
				};

				if (data.action === 'picked') {
					const { docs } = data;
					const { id } = docs[0];

					const response = await ConnectionService.saveConnectionGoogleSheets(connectionId, id);
					const { code } = response;
					if (code === 200) {
						fetchData();
					};
				};

				console.log(data)
			},
		})
    };

    return (
        <>
          	<NavigationBar />
          	<div className="w-full h-screen bg-slate-200">
            	<div className="flex justify-center py-4">
                	<button
                  		className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  		onClick={handleOpenPicker}>
                  		Open Google Drive
                	</button>
            	</div>
				<div className="px-8">
					<div>
						<h2 className="text-lg font-bold">Saved Spreadsheets</h2>
					</div>
					{spreadsheets.map(spreadsheet => (
						<div key={spreadsheet.id} className="bg-white shadow-md rounded max-w-md p-2 mb-2">
							<div className="px-4 py-2">
                                <h3 className="text-base font-semibold">{spreadsheet.title}</h3>
                            </div>
							{spreadsheet.sheets.map(sheet => (
								<div key={sheet.id} className="px-6">
									<p>{sheet.title}</p>
								</div>
							))}
						</div>
					))}
				</div>
            </div>
        </>
    )
}

export default GoogleSheetsExplorerPage;
