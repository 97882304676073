import { protectedService } from './axios';

class ConnectionService {
    async getConnections() {
        return protectedService.get('/connections')
            .then(e => {
                const { data } = e;
                const { payload } = data;
                return payload;
            })
            .catch(e => {
                const { response } = e;
                const { data } = response;
                return data;
            });
    };

    async saveConnection(name, google_auth_id) {
        return protectedService.post('/connections', {name, google_auth_id})
            .then(e => {
                const { data } = e;
                return data;
            })
            .catch(e => {
                const { response } = e;
                const { data } = response;
                return data;
            });
    };

    async getConnectionGoogleSheets(connection_id) {
        return protectedService.get(`/connections/${connection_id}/google-sheets`)
            .then(e => {
                const { data } = e;
                const { payload } = data;
                return payload;
            })
            .catch(e => {
                const { response } = e;
                const { data } = response;
                return data;
            });
    };

    async saveConnectionGoogleSheets(connection_id, spreadsheet_id) {
        return protectedService.post(`/connections/${connection_id}/google-sheets`, {spreadsheet_id})
            .then(e => {
                const { data } = e;
                return data;
            })
            .catch(e => {
                const { response } = e;
                const { data } = response;
                return data;
            });
    };
};

export default new ConnectionService();
